<template>
	<CFooter>
		<div>
			<span class="ms-1">&copy; {{ new Date().getFullYear() }} Bottle Raiders</span>
		</div>
	</CFooter>
</template>

<script>
export default {
  name: 'AdminFooter'
}
</script>
