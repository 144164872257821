<template>
	<CSidebar
		position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible"
		@visible-change="(event) => $store.commit({type: 'updateSidebarVisible',value: event})">

		<CSidebarBrand>
			<img class="sidebar-brand-full" alt="Bottle Raiders Logo" width="200" :src="require('@/assets/deeplink/logo.png')" />
			<img class="sidebar-brand-narrow" width="63" :src="require('@/assets/logo4.png')" />
		</CSidebarBrand>

		<CSidebarNav>
			<CNavItem>
				<router-link class="nav-link" :to="{ name: 'Dashboard' }" exact exact-active-class="active"><CIcon  customClassName="nav-icon" icon="cil-speedometer"/> Dashboard</router-link>
			</CNavItem>
			<!-- User Management -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['User List','User Create','User Edit']) }" class="nav-link" :to="{ name: 'User List' }"><CIcon  customClassName="nav-icon" icon="cil-user"/> User Management</router-link>
			</CNavItem>
			<!-- Business User Management -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Business User List','Business User Create','Business User Edit']) }" class="nav-link" :to="{ name: 'Business User List' }"><CIcon  customClassName="nav-icon" icon="cil-user"/> Business User</router-link>
			</CNavItem>
			<!-- Article Management -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Article List']) }" class="nav-link" :to="{ name: 'Article List' }"><CIcon  customClassName="nav-icon" icon="cil-puzzle"/> Article Management</router-link>
			</CNavItem>
			<!-- Category Management -->
			<!-- <CNavItem>
				<router-link :class="{ active: isRouteActive(['CategoryList', 'CategoryCreate', 'CategoryEdit']) }" class="nav-link" :to="{ name: 'CategoryList' }"><CIcon  customClassName="nav-icon" icon="cil-list"/> Category Management</router-link>
			</CNavItem> -->
			<!-- Bottle Type -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Bottle Type List', 'Bottle Type Create', 'Bottle Type Edit']) }" class="nav-link" :to="{ name: 'Bottle Type List' }"><CIcon  customClassName="nav-icon" icon="cil-options"/> Bottle Types</router-link>
			</CNavItem>
			<!-- Spirit -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Spirit List', 'Spirit Create', 'Spirit Edit', 'Spirit View']) }" class="nav-link" :to="{ name: 'Spirit List' }"><CIcon  customClassName="nav-icon" icon="cil-drink-alcohol"/> Spirits</router-link>
			</CNavItem>
			<!-- User Review -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['User Review List']) }" class="nav-link" :to="{ name: 'User Review List' }"><CIcon  customClassName="nav-icon" icon="cil-star"/> User Reviews</router-link>
			</CNavItem>
			<!-- Subscription Page -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Subscription Content List','Subscription Content Edit']) }" class="nav-link" :to="{ name: 'Subscription Content List' }"><CIcon  customClassName="nav-icon" icon="cil-notes"/> Subscription Page</router-link>
			</CNavItem>
			<!-- Spirit -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Subscription List', 'Subscription Create', 'Subscription Edit', 'Subscription View']) }" class="nav-link" :to="{ name: 'Subscription List' }"><CIcon  customClassName="nav-icon" icon="cil-list"/> Subscription Management </router-link>
			</CNavItem>
			<!-- Custom Notification -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Custom Notification List', 'Custom Notification Create', 'Custom Notification Edit', 'Custom Notification View']) }" class="nav-link" :to="{ name: 'Custom Notification List' }"><CIcon  customClassName="nav-icon" icon="cil-bell"/> Custom Notification</router-link>
			</CNavItem>
			<!-- Scanner Log -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Scanner Log List']) }" class="nav-link" :to="{ name: 'Scanner Log List' }"><CIcon  customClassName="nav-icon" icon="cil-newspaper"/> Scanner Logs</router-link>
			</CNavItem>
			<!-- Sponsered Spirit -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Sponsored Spirit List']) }" class="nav-link" :to="{ name: 'Sponsored Spirit List' }"><CIcon  customClassName="nav-icon" icon="cil-bell"/> Sponsored Spirit</router-link>
			</CNavItem>
			<!-- Notification Preference List -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['NotificationPreferenceList']) }" class="nav-link" :to="{ name: 'NotificationPreferenceList' }"><CIcon  customClassName="nav-icon" icon="cil-bell"/> Notification Preference</router-link>
			</CNavItem>
		</CSidebarNav>
		<CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')"/>
	</CSidebar>
</template>
  
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
// import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
	name: 'AppSidebar',
	data() {
		return {
            isDeveloper:false
		}
	},
	setup() {
		const store = useStore()
		return {
			logoNegative,
			sygnet,
			sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
			sidebarVisible: computed(() => store.state.sidebarVisible),
		}
	},
	methods: {
		isRouteActive(routeName) {
			return routeName.includes(this.$route.name);
		}
	}
}
</script>

<style scoped>
	.sidebar-fixed {width: 14.3% !important;}

	@media screen and (max-width:1599px) {
		.sidebar.sidebar-fixed .sidebar-nav .nav-link {font-size: 15px !important;}
	}
</style>
