<template>
	<CBreadcrumb class="d-md-down-none mb-0">
		<CBreadcrumbItem
			v-for="item in breadcrumbs"
			:key="item"
			:active="item.active"
			>
			<router-link :to="item.active ? '' : item.path">{{ item.name }}</router-link>
		</CBreadcrumbItem>
	</CBreadcrumb>
</template>
  
<script>
	import { onMounted, ref } from 'vue'
	import router from '@/router'

	export default {
		name: 'AdminBreadcrumb',
		setup() {
			const breadcrumbs = ref()

			const getBreadcrumbs = () => {
				const currentRoute = router.currentRoute.value;

				return currentRoute.matched.map((route) => {
					return {
						active: route.name === currentRoute.name,
						name: route.name,
						path: route.path.includes(':') ? currentRoute.path : `${router.options.history.base}${route.path}`,
					};
				});
			};

			router.afterEach(() => {
				breadcrumbs.value = getBreadcrumbs()
			})

			onMounted(() => {
				breadcrumbs.value = getBreadcrumbs()
			})

			return {
				breadcrumbs,
			}
		},
	}
</script>
  